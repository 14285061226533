import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import {
  IconEditCrossed,
  IconExternal,
  IconFigma,
  IconTokensStudioColored,
} from "@supernovaio/icons"

import { DMIcon } from "../../DMIcon"
import { DMTooltip } from "../../DMTooltip"
import { ResolvedStyle, TokenPreview, TokenType } from "../DMTokenPreview.types"
import { ReferenceBadgeIcon } from "../assets/ReferenceBadgeIcon"
import { ColorSwatch } from "../utils/swatches"
import { InnerBlockTile, TextTile } from "../utils/tiles"
import { isTypographyTokenMetadata } from "../utils/token-utils"

export type DMTokenPreviewTileProps = {
  token: TokenPreview | null
  size?: "medium" | "large"
  resolvedStyle?: ResolvedStyle
  isEdgeToEdge?: boolean
  isValueHidden?: boolean
  forcedDisplayText?: string
  sourceInfo?: {
    type: "Figma" | "TokenStudio" | "FigmaVariablesPlugin" | undefined
    filename: string | null | undefined
    fileUrl: string | undefined
  }
  themes?: string[]
}

export function DMTokenPreviewTile(props: DMTokenPreviewTileProps) {
  const {
    token,
    size = "medium",
    resolvedStyle,
    sourceInfo,
    isEdgeToEdge,
    isValueHidden = false,
    forcedDisplayText,
    themes,
  } = props

  // If there is no info about token, we render empty tile
  if (!token) {
    return (
      <div
        className={cn(
          "text-neutral-faded bg-dotted border-neutral-faded flex w-full select-none items-center justify-center border border-dashed px-12 min-w-[64px]",
          isEdgeToEdge ? "rounded-none" : "rounded",
          size === "medium" ? "h-[48px]" : "h-[180px]"
        )}
      >
        Empty
      </div>
    )
  }

  const tokenTypeSwatch: Partial<{ [key in TokenType]: React.ReactNode }> = {
    blur: (
      <InnerBlockTile
        background="#000000CC"
        blur={token.displayValue}
        borderWidth="0"
        themes={themes}
      />
    ),
    borderWidth: (
      <InnerBlockTile
        displayText={token.displayValue}
        {...resolvedStyle}
        themes={themes}
      />
    ),
    border: <InnerBlockTile {...resolvedStyle} themes={themes} />,
    color: (
      <ColorSwatch background={resolvedStyle?.background} themes={themes} />
    ),
    copy: <TextTile cssValue={token.displayValue} themes={themes} />,
    dimension: <TextTile cssValue={token.displayValue} themes={themes} />,
    duration: <TextTile cssValue={token.displayValue} themes={themes} />,
    fontFamily: (
      <TextTile
        cssValue={token.displayValue}
        {...resolvedStyle}
        themes={themes}
      />
    ),
    fontSize: (
      <TextTile
        cssValue={token.displayValue}
        {...resolvedStyle}
        fontSizeValue={
          isTypographyTokenMetadata(token?.meta) ? token?.meta?.fontSize : null
        }
        themes={themes}
      />
    ),
    fontWeight: (
      <TextTile
        cssValue={token.displayValue}
        {...resolvedStyle}
        themes={themes}
      />
    ),
    gradient: <ColorSwatch {...resolvedStyle} themes={themes} />,
    lineHeight: (
      <TextTile
        cssValue={token.displayValue}
        {...resolvedStyle}
        themes={themes}
      />
    ),
    letterSpacing: (
      <TextTile
        cssValue={token.displayValue}
        displayText={forcedDisplayText || "Hello"}
        letterSpacing={token.displayValue}
        themes={themes}
      />
    ),
    opacity:
      resolvedStyle?.opacity !== "1" && resolvedStyle?.opacity !== "0" ? (
        <InnerBlockTile
          background="#000000"
          borderWidth="0"
          opacity={resolvedStyle?.opacity}
          themes={themes}
        />
      ) : (
        <InnerBlockTile
          background="transparent"
          borderWidth="0"
          displayText={
            resolvedStyle?.opacity === "1" ? "Non-transparent" : "Transparent"
          }
          themes={themes}
        />
      ),

    paragraphSpacing: (
      <TextTile cssValue={token.displayValue} themes={themes} />
    ),
    radius: (
      <InnerBlockTile
        displayText={token.displayValue}
        {...resolvedStyle}
        themes={themes}
      />
    ),
    shadow: (
      <InnerBlockTile
        boxShadow={token.displayValue}
        displayText={undefined}
        themes={themes}
      />
    ),
    size: <TextTile cssValue={token.displayValue} themes={themes} />,
    space: <TextTile cssValue={token.displayValue} themes={themes} />,
    string: <TextTile cssValue={token.displayValue} themes={themes} />,
    textCase: (
      <TextTile
        cssValue={token.displayValue}
        textTransform={token.displayValue}
        themes={themes}
      />
    ),
    textDecoration: (
      <TextTile
        cssValue={token.displayValue}
        textDecoration={token.displayValue}
        themes={themes}
      />
    ),
    visibility: (
      <InnerBlockTile
        background="transparent"
        borderWidth="0"
        displayText={token.displayValue === "true" ? "Visible" : "Hidden"}
        themes={themes}
      />
    ),
    typography: (
      <TextTile
        cssValue={token.displayValue}
        {...resolvedStyle}
        fontSizeValue={
          isTypographyTokenMetadata(token?.meta) ? token?.meta?.fontSize : null
        }
        themes={themes}
        displayText={forcedDisplayText}
      />
    ),
    zIndex: <TextTile cssValue={token.displayValue} themes={themes} />,
  }

  const getTokenTypeString = () => {
    // Because TokenStudio is on backend but the actual name of the service is Tokens Studio
    if (sourceInfo?.type === "TokenStudio") return "Tokens Studio"
    if (sourceInfo?.type === "FigmaVariablesPlugin")
      return "Figma Variables Plugin"
    return sourceInfo?.type
  }

  const sourceDescription = sourceInfo?.filename ? (
    sourceInfo?.fileUrl ? (
      <a
        className={cn(
          "group/link",
          "inline-flex flex-row items-center gap-2",
          "hover:text-primary hover:underline"
        )}
        href={sourceInfo.fileUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        {sourceInfo.filename}
        <DMIcon className="hidden group-hover/link:block" svg={IconExternal} />
      </a>
    ) : (
      sourceInfo?.filename
    )
  ) : (
    `Token was imported from ${getTokenTypeString()}`
  )

  const sourceIcon =
    sourceInfo?.type === "TokenStudio" ? IconTokensStudioColored : IconFigma

  return (
    <div className={cn("flex flex-col relative")}>
      {token?.referencedTo && (
        <ReferenceBadgeIcon className="z-2 absolute -left-4 -top-4 text-neutral" />
      )}
      <div
        className={cn(
          "min-w-[64px] h-[48px] w-full overflow-hidden",
          sourceInfo?.type
            ? "rounded-t [&_.preview-tile]:rounded-b-none"
            : "rounded",
          token?.tokenType !== "color" &&
            "bg-dotted border-neutral-faded border",
          size === "medium" ? "h-[48px]" : "h-[180px]",
          isEdgeToEdge &&
            "rounded-none border-x-0 border-t-0 [&_.preview-tile]:rounded-none [&_.preview-tile]:border-x-0 [&_.preview-tile]:border-t-0",
          {
            "clip-path-token-swatch": token?.referencedTo,
          }
        )}
      >
        <div
          className={cn(
            "align-center flex h-full w-full items-center overflow-hidden relative",
            isValueHidden && "hidden"
          )}
        >
          {tokenTypeSwatch[token?.tokenType]}
        </div>
      </div>
      {sourceInfo?.type && (
        <div
          className={cn(
            "align-center flex flex-row justify-between rounded-b p-8",
            "text-neutral-faded text-small bg-neutral-faded border-neutral-faded font-semibold",
            "rounded-b border border-t-0",
            isEdgeToEdge && "rounded-none border-l-0 border-r-0 px-24"
          )}
        >
          <div className="flex flex-row gap-4">
            <DMIcon svg={sourceIcon} />
            {sourceDescription}
          </div>
          <DMTooltip text="Token value, name, and description is read-only.">
            <DMIcon svg={IconEditCrossed} />
          </DMTooltip>
        </div>
      )}
    </div>
  )
}
