"use client"

import * as React from "react"

import { DMDivider, DMIcon } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCaret } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import * as AccordionPrimitive from "@radix-ui/react-accordion"

import { DMSpacing } from "../../types/spacing"

import { accordionBleedSpacing } from "./utils"

type DMAccordionProps = Pick<
  Partial<React.PropsWithoutRef<AccordionPrimitive.AccordionMultipleProps>>,
  "defaultValue" | "value" | "className" | "children" | "onValueChange"
> & {
  bleed?: DMSpacing
}

const DMAccordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  DMAccordionProps
>(({ className, children, bleed, ...props }, ref) => (
  <AccordionPrimitive.Root
    ref={ref}
    className={cn(
      "overflow-y-clip",
      bleed && accordionBleedSpacing[bleed],
      className
    )}
    {...props}
    type="multiple"
  >
    <DMDivider bleed={bleed} />
    {children}
  </AccordionPrimitive.Root>
))

DMAccordion.displayName = AccordionPrimitive.Root.displayName

type DMAccordionTriggerProps = React.ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Trigger
> & {
  headerStartSlot?: React.ReactNode
  headerEndSlot?: React.ReactNode
  headerIcon?: IconType
  isExpandable?: boolean
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  DMAccordionTriggerProps
>(
  (
    {
      className,
      children,
      headerIcon,
      headerEndSlot,
      headerStartSlot,
      isExpandable,
      ...props
    },
    ref
  ) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "accordion-header",
          "group/accordion-header",
          "flex min-h-[52px] flex-auto items-center justify-between px-12 outline-none transition-[background-color] w-full gap-8",
          className,
          {
            "hover:bg-neutral-faded cursor-pointer": isExpandable,
            "cursor-default": !isExpandable,
          }
        )}
        {...props}
      >
        <div
          className={cn(
            "-ml-4 flex items-center justify-between gap-8 rounded p-4 shrink-0",
            "group-focus-visible/accordion-header:ring-2"
          )}
        >
          {headerIcon && <DMIcon svg={headerIcon} />}
          <div className="flex flex-row items-center">
            <div className="text-body font-bold text-left">{children}</div>
            {isExpandable && (
              <DMIcon
                className="transition-transform group-data-[state=open]:rotate-90"
                color="neutral-faded"
                size="medium"
                svg={IconCaret}
              />
            )}
          </div>
          {headerStartSlot && <div>{headerStartSlot}</div>}
        </div>
        {headerEndSlot && (
          <div className="ml-auto overflow-hidden">{headerEndSlot}</div>
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
)

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-x-hidden overflow-y-hidden",
      className
    )}
    {...props}
  >
    <div className="accordion-content px-12 py-8">{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

type DMAccordionItemProps = Pick<
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>,
  "className" | "children"
> & {
  value: string
  title: string
  isExpandable?: boolean
} & Pick<
    DMAccordionTriggerProps,
    "headerEndSlot" | "headerStartSlot" | "headerIcon"
  >

const DMAccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  DMAccordionItemProps
>(
  (
    {
      className,
      title,
      children,
      headerEndSlot,
      headerStartSlot,
      headerIcon,
      isExpandable = true,
      ...props
    },
    ref
  ) => (
    <AccordionPrimitive.Item
      ref={ref}
      className={cn(
        "accordion-item",
        "border-neutral-faded group border-b",
        className
      )}
      {...props}
    >
      <AccordionTrigger
        headerEndSlot={headerEndSlot}
        headerIcon={headerIcon}
        headerStartSlot={headerStartSlot}
        isExpandable={children ? isExpandable : false}
      >
        {title}
      </AccordionTrigger>
      {children && <AccordionContent>{children}</AccordionContent>}
    </AccordionPrimitive.Item>
  )
)

DMAccordionItem.displayName = "DMAccordionItem"

export { DMAccordion, DMAccordionItem }
