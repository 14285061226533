import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconError, IconHelp } from "@supernovaio/icons"

import DMIcon from "../../../DMIcon/DMIcon"
import { DMTooltip } from "../../../DMTooltip"
import { TokenValueSegment } from "../../DMTokenPreview.types"

import { cva } from "class-variance-authority"

export type TokenValueSegmentProps = {
  segment: TokenValueSegment | "separator"
  isTooltipEnabled?: boolean
  forceRawValue?: boolean
}

const badgeClassname = "px-4 rounded gap-4"

const segmentClassname = cva("flex items-center", {
  variants: {
    variant: {
      value: "",
      reference: [badgeClassname, "bg-neutral border border-neutral-faded"],
      error: [
        badgeClassname,
        "border border-dashed border-critical-faded text-critical bg-critical-faded line-through",
      ],
      "error-value": [badgeClassname, "text-critical line-through"],
      warning: [
        badgeClassname,
        "border border-dashed border-warning-faded text-warning bg-warning-faded",
      ],
    },
  },
})

export function ValueSegment(props: TokenValueSegmentProps) {
  const { segment, isTooltipEnabled = false, forceRawValue = false } = props

  if (segment === "separator") {
    return (
      <div className={cn("text-neutral-faded -mx-2 flex items-center")}>/</div>
    )
  }

  // Resolving what should be displayed
  let displayValueText

  if (forceRawValue) {
    displayValueText = segment.value
  } else if (segment.prefersReferenceName) {
    displayValueText = segment.referencedName || segment.value
  } else {
    displayValueText = segment.value
  }

  const tooltipText = segment.referenceFullPath ?? segment.referencedName

  const variant = segment.referencedName ? "reference" : "value"

  if (
    variant === "reference" &&
    (segment.isDifferentTypeValue || segment.isValueOutOfRange)
  ) {
    return (
      <DMTooltip
        text={
          segment.isDifferentTypeValue
            ? "Referenced variable has incompatible unit"
            : "Referenced variable contains invalid value"
        }
        textSecondary={`Reference: ${tooltipText}`}
      >
        <div className={segmentClassname({ variant: "error" })}>
          <DMIcon svg={IconError} />
          {displayValueText}
        </div>
      </DMTooltip>
    )
  }

  if (
    variant === "value" &&
    (segment.isDifferentTypeValue || segment.isValueOutOfRange)
  ) {
    return (
      <DMTooltip
        text={
          segment.isDifferentTypeValue
            ? "Variable value has incompatible unit"
            : "Variable value contains invalid value"
        }
      >
        <div className={segmentClassname({ variant: "error-value" })}>
          <DMIcon svg={IconError} />
          {displayValueText}
        </div>
      </DMTooltip>
    )
  }

  if (segment.referenceResolutionFailed) {
    return (
      <DMTooltip
        text={
          segment.referenceOriginRemote
            ? "Referenced variable was not imported"
            : "Referenced variable was deleted"
        }
        textSecondary={`Reference: ${segment.referenceOriginName}`}
      >
        <div
          className={segmentClassname({
            variant: "warning",
          })}
        >
          <DMIcon svg={IconHelp} />
          {displayValueText}
        </div>
      </DMTooltip>
    )
  }

  return (
    <DMTooltip
      text={tooltipText ? `Reference: ${tooltipText}` : undefined}
      textSecondary={`Value: ${segment.value}`}
      isHidden={!(isTooltipEnabled && segment.referencedName)}
    >
      <div className={segmentClassname({ variant })}>{displayValueText}</div>
    </DMTooltip>
  )
}
