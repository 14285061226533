import React, { useCallback } from "react"

import { DMTooltip } from "@supernovaio/dm"
import Actionable from "@supernovaio/dm/src/components/Actionable/Actionable"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { cva } from "class-variance-authority"

// TODO The interface of this component probably needs to change to accept arbitrary string values
// The logic behind checking the possible values should most likely be implemented in higher levels

export enum ToggleButtonUnitEnum { // NOTE: used for zod
  "px" = "Pixels",
  "rem" = "Rem",
  "%" = "Percent",
  "ms" = "ms",
  "raw" = "Raw",
}

export const toggleButtonUnits = Object.values(ToggleButtonUnitEnum) as [
  (typeof ToggleButtonUnitEnum)["px"],
  (typeof ToggleButtonUnitEnum)["rem"],
  (typeof ToggleButtonUnitEnum)["%"],
  (typeof ToggleButtonUnitEnum)["ms"],
  (typeof ToggleButtonUnitEnum)["raw"]
]

export type ToggleButtonUnit = (typeof toggleButtonUnits)[number]

// TODO: This is now unsafe, change it to a prop containing unit labels and descriptions
const toggleButtonUnitLabels: { [key in string]: string } = {
  [ToggleButtonUnitEnum.px]: "px",
  [ToggleButtonUnitEnum.rem]: "rem",
  [ToggleButtonUnitEnum["%"]]: "%",
  [ToggleButtonUnitEnum.ms]: "ms",
  [ToggleButtonUnitEnum.raw]: "–",
}

// TODO: This is now unsafe, change it to a prop containing unit labels and descriptions
const toggleButtonUnitDescriptions: { [key in string]: string } = {
  Pixels: "Pixels",
  rem: "rem",
  Percent: "percentage",
  ms: "milliseconds",
  Raw: "no unit",
}

const textVariants = cva(["text-body"], {
  variants: {
    isEditable: {
      true: "text-neutral",
      false: "text-neutral-faded",
    },
  },
})

export type DMUnitToggleButtonProps = {
  selectedUnit: string
  allowedUnits: string[]
  onUnitChange: (unit: string) => void
}

export function DMUnitToggleButton({
  selectedUnit,
  allowedUnits,
  onUnitChange,
}: DMUnitToggleButtonProps) {
  if (allowedUnits.length === 0) {
    throw new Error("DMUnitToggleButton: allowedUnits cannot be empty")
  }

  if (!allowedUnits.includes(selectedUnit)) {
    throw new Error(
      "DMUnitToggleButton: selectedUnit must be one of allowedUnits"
    )
  }

  const isEditable = allowedUnits.length > 1

  const memoizedOnClick = useCallback(() => {
    const currentIndex = allowedUnits.indexOf(selectedUnit)
    const nextIndex = currentIndex + 1
    // We've already checked that allowedUnits is not empty
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nextUnit = allowedUnits[nextIndex] || allowedUnits[0]!

    if (selectedUnit !== nextUnit) {
      onUnitChange(nextUnit)
    }
  }, [selectedUnit, allowedUnits, onUnitChange])

  const description = `Selected unit: ${toggleButtonUnitDescriptions[selectedUnit]}`

  return (
    <div
      className={cn("", {
        "pointer-events-none": !isEditable,
      })}
    >
      <DMTooltip isHidden={allowedUnits.length === 1} text="Change unit">
        <Actionable
          attributes={{
            "aria-label": isEditable ? "Change unit" : undefined,
            "aria-description": description,
          }}
          className="h-form-small w-form-small hover:bg-neutral-faded rounded transition-colors duration-300 focus-visible:ring-2"
          onClick={memoizedOnClick}
        >
          <span className={textVariants({ isEditable })}>
            {toggleButtonUnitLabels[selectedUnit]}
          </span>
        </Actionable>
      </DMTooltip>
    </div>
  )
}
